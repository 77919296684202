import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import Button from '@UI/Button';
import styles from './AllFiltersButton.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  name?: string;
  theme?: 'secondary' | 'primary';
}

const AllFiltersButton: FC<ButtonProps> = (props) => {
  const { className, name, theme = 'primary', ...restProps } = props;
  const { t } = useTranslation();

  return (
    <Button
      {...restProps}
      className={cn(
        styles.button,
        styles.main,
        { [styles.secondary]: theme === 'secondary' },
        className,
      )}
      view='rounded'
      theme={theme}
      before={<div className={styles.iconFilters} />}
    >
      {name ? name : t('ui.filters.all')}
    </Button>
  );
};

export default memo(AllFiltersButton);
