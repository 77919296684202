import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { FiltersObjectData, FiltersData } from '@Types/Filters';

export interface Params {
  filters: FiltersObjectData;
}

export interface Response {
  productsTotalCount: number;
  filters: FiltersData;
}

async function getFavoritesProductsCount(params: Params) {
  //TODO: перевести на единый механизм после переезда Кабинета на php
  const { language, defaultLanguage } = Api.getRequest();
  const lang = language.id === defaultLanguage ? '' : `/${language.slug}`;
  const { filters } = params;

  const url = `/cabinet${lang}/favorites/get-products-count`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(filters),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getFavoritesProductsCount;
