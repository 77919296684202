import { memo, useCallback } from 'react';
import { cn, Scroller, Popup } from '@divlab/divanui';

import ListingGroupLinks from '@Components/ListingGroupLinks';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import useMedias from '@Hooks/useMedias';
import styles from './GroupsPopup.module.css';

import type { GroupData } from '../../typings';
import type { FC, HTMLAttributes, MouseEvent as ReactMouseEvent } from 'react';
import type { FilterView } from '@Types/Filters';

export interface GroupsPopupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  label: string;
  groups: GroupData[];
  visible?: boolean;
  filterView?: FilterView;
  onClose?: (e: MouseEvent | ReactMouseEvent) => void;
}

const GroupsPopup: FC<GroupsPopupProps> = (props) => {
  const { className, label, groups, visible, filterView, onClose, ...restProps } = props;
  const { isMobileM } = useMedias();

  const ref = useOnClickOutside<HTMLDivElement>(onClose, !visible);

  const handleClose = useCallback(
    (e: MouseEvent | ReactMouseEvent) => {
      if (onClose) onClose(e);
    },
    [onClose],
  );

  return (
    <Popup
      {...restProps}
      className={cn(styles.popup, { [styles.visible]: visible }, className)}
      visible={visible}
      ref={ref}
    >
      <div className={styles.containerLabel} onClick={handleClose}>
        <span className={styles.label}>{label}</span>
        <div className={styles.arrow} />
      </div>
      <Scroller className={styles.scroller}>
        <div
          className={cn(styles.content, {
            [styles.column1]: groups.length === 1,
            [styles.column2]: groups.length === 2,
            [styles.column3]: groups.length === 3 || isMobileM,
            [styles.column4]: groups.length >= 4 && !isMobileM,
          })}
        >
          {groups.map((group) => (
            <ListingGroupLinks
              key={group.groupName}
              className={styles.group}
              group={group}
              filterView={filterView}
              onClose={onClose}
            />
          ))}
        </div>
      </Scroller>
    </Popup>
  );
};

export default memo(GroupsPopup);
