import { cloneElement, memo, useCallback, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn } from '@divlab/divanui';

import styles from './Dropdown.module.css';

import type { FC, HTMLAttributes, MouseEvent, ReactElement } from 'react';

export interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  label: string;
  children?: ReactElement;
  opened?: boolean;
  popupWidth?: number;
  onOpen?: (e: MouseEvent) => void;
  onClose?: (e: MouseEvent) => void;
  testid?: string;
}

type Position = 'left' | 'right';

const Dropdown: FC<DropdownProps> = (props) => {
  const { className, label, children, opened, popupWidth, testid, onOpen, onClose, ...restProps } =
    props;
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<Position>('left');

  const calculatePopupPosition = useCallback(
    (e: MouseEvent) => {
      const windowInnerWidth = window.innerWidth;
      const rectTab = e.currentTarget?.getBoundingClientRect();
      if (!popupWidth || rectTab.x + popupWidth > windowInnerWidth) {
        setPosition('left');
      }
      if (rectTab.x + rectTab.width - popupWidth < 0) {
        setPosition('right');
      }
    },
    [popupWidth],
  );

  const handleClickLabel = useCallback(
    (e: MouseEvent) => {
      if (onOpen && !visible) onOpen(e);
      setVisible((prev) => !prev);
      calculatePopupPosition(e);
    },
    [onOpen, visible, calculatePopupPosition],
  );

  const handleClose = useCallback(
    (e: MouseEvent) => {
      if (onClose) onClose(e);
      setVisible(false);
    },
    [onClose],
  );

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  return (
    <div
      {...restProps}
      data-testid={testid}
      className={cn(styles.dropdown, { [styles.visible]: visible }, className)}
    >
      <div className={styles.containerLabel} onClick={handleClickLabel}>
        <span className={styles.label}>{label}</span>
        <div className={styles.arrow} />
      </div>
      {children && (
        <CSSTransition
          classNames={{
            enterDone: styles.enterDone,
            enter: styles.enter,
            exit: styles.exit,
            exitDone: styles.exitDone,
          }}
          in={visible}
          unmountOnExit
          timeout={100}
        >
          {cloneElement(children, {
            ...children.props,
            visible,
            className: cn(children.props.className, styles.popup, {
              [styles.right]: position === 'right',
              [styles.left]: position === 'left',
            }),
            onClose: handleClose,
          })}
        </CSSTransition>
      )}
    </div>
  );
};

export default memo(Dropdown);
