import { memo } from 'react';
import { cn, Breadcrumbs } from '@divlab/divanui';

import styles from './BreadcrumbsWrapper.module.css';

import type { FC } from 'react';
import type { BreadcrumbsProps } from '@divlab/divanui';

export interface BreadcrumbsWrapperProps extends BreadcrumbsProps {
  breadcrumbsSeoData?: unknown;
}

const BreadcrumbsWrapper: FC<BreadcrumbsWrapperProps> = (props) => {
  const { className, breadcrumbs, breadcrumbsSeoData, ...restProps } = props;

  return (
    <>
      {breadcrumbs.length > 0 ? (
        <>
          <Breadcrumbs {...restProps} breadcrumbs={breadcrumbs} className={className} />
          {!!breadcrumbsSeoData && (
            <script
              type='application/ld+json'
              dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsSeoData) }}
            />
          )}
        </>
      ) : (
        <div {...restProps} className={cn(styles.breadcrumbs, className)}></div>
      )}
    </>
  );
};

export default memo(BreadcrumbsWrapper);
