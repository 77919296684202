import * as Filtrator from '@Stores/Filtrator';

import type { FiltersData, FiltersValue } from '@Types/Filters';

const id = 'catalog';

export function init(initial: FiltersData) {
  return Filtrator.init(id, initial);
}

export const formatFiltersToObject = () => {
  return Filtrator.formatFiltersToObject(id);
};

export const createUrlForValues = (parameterValues: FiltersValue[]) => {
  return Filtrator.createUrlForValues(id, parameterValues);
};

export const updateTotalCount = (totalCount: number) => {
  return Filtrator.updateTotalCount(id, totalCount);
};

export function useFiltrator() {
  return Filtrator.useFiltrator(id);
}

export function useSelected() {
  return Filtrator.useSelected(id);
}

export function useAppliedValues() {
  return Filtrator.useAppliedValues(id);
}

export function useActiveCategoryIds() {
  return Filtrator.useActiveCategoryIds(id);
}

export function usePriorityFilters() {
  return Filtrator.usePriorityFilters(id);
}

export function useHasChangedFilters() {
  return Filtrator.useHasChangedFilters(id);
}

export function useTotalCount() {
  return Filtrator.useTotalCount(id);
}

export function useResetLink() {
  return Filtrator.useResetLink(id);
}

export function changeCount(filters: FiltersData) {
  return Filtrator.changeCount(id, filters);
}
