import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@divlab/divanui';

import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import * as ModalWindows from '@Stores/ModalWindows';
import { useDeps } from '@Contexts/DI';
import useMedias from '@Hooks/useMedias';
import useSearchParams from '@Hooks/useSearchParams';
import useTranslation from '@Queries/useTranslation';
import useNavigation from '@Navigation/useNavigation';
import Dropdown from '../Dropdown';
import GroupsPopup from '../GroupsPopup';
import OptionsPopup from '../OptionsPopup';
import styles from './Labels.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { GroupData } from '../../typings';
import type { FilterView } from '@Types/Filters';
import type { OptionData } from '@Modals/MobileOptionsModal';

export interface LabelsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  groups?: GroupData[];
  filterView?: FilterView;
}

type ModalName = 'groups' | 'options';

const Labels: FC<LabelsProps> = (props) => {
  const { groups, filterView, className } = props;
  const filtrator = CatalogFiltrator.useFiltrator();
  const [openedGroups, setOpenedGroups] = useState(false);
  const [openedOptions, setOpenedOptions] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const { isMobile, isMobileM } = useMedias();
  const { pathname } = useNavigation();
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const { analytics } = useDeps();
  const isViewSticky = filterView === 'sticky';

  const options = useMemo(() => {
    return filtrator.sort.map((item) => {
      searchParams.set('sort', item.id);
      return { ...item, link: `${pathname}?${searchParams.toString()}` };
    });
  }, [filtrator.sort, pathname, searchParams]);

  const labelSort = useMemo(() => {
    const { name } = filtrator.sort.find((item) => item.selected);

    return `${name.substring(0, 1).toUpperCase()}${name.substring(1)}`;
  }, [filtrator.sort]);

  const handleCloseModal = useCallback((modalName: ModalName) => {
    if (modalName === 'groups') {
      setOpenedGroups(false);
    } else {
      setOpenedOptions(false);
    }
    setModalOpened(false);
  }, []);

  // Загружаем модальное окно по группам на всех разрешениях согласно требованиям СЕО.
  ModalWindows.useSSRModals(
    [
      {
        id: 'MobileGroups',
        data: {
          label: 'По группам',
          groups,
          onClose: () => {
            handleCloseModal('groups');
          },
        },
      },
    ],
    groups?.length > 0,
  );

  const handleOpenGroups = useCallback(() => {
    if (openedOptions) {
      setOpenedOptions(false);
    }
    setTimeout(() => {
      setOpenedGroups(true);
    }, 100);

    if (!isMobileM) return;

    if (groups?.length > 0)
      return ModalWindows.open('MobileGroups', {
        label: 'По группам',
        groups,
        onClose: () => {
          handleCloseModal('groups');
        },
      });
    setModalOpened(true);
  }, [groups, handleCloseModal, isMobileM, openedOptions]);

  const handleCheck = useCallback(
    (e: MouseEvent, option: OptionData) => {
      analytics.dispatchEvent('catalog.sorting.accept', {
        optionName: option.name,
        status: isViewSticky ? 'закреп' : 'не закреп',
      });
    },
    [analytics, isViewSticky],
  );

  const handleOpenOptions = useCallback(() => {
    if (openedGroups) {
      setOpenedGroups(false);
    }
    setTimeout(() => {
      setOpenedOptions(true);
    }, 100);

    if (!isMobile) return;

    ModalWindows.open('MobileOptions', {
      label: 'Выводить сначала',
      options,
      onCheck: handleCheck,
      onClose: () => {
        handleCloseModal('options');
      },
    });
    setModalOpened(true);
  }, [openedGroups, isMobile, options, handleCheck, handleCloseModal]);

  useEffect(() => {
    if (isMobile && openedOptions) {
      ModalWindows.open('MobileOptions', {
        label: 'Выводить сначала',
        options,
        onCheck: handleCheck,
        onClose: () => {
          handleCloseModal('options');
        },
      });
      setModalOpened(true);
    }

    if (!isMobile && modalOpened) {
      ModalWindows.close('MobileOptions');
      setModalOpened(false);
    }
  }, [handleCheck, handleCloseModal, isMobile, modalOpened, openedOptions, options]);

  useEffect(() => {
    if (isMobileM && openedGroups && groups?.length > 0) {
      ModalWindows.open('MobileGroups', {
        label: 'По группам',
        groups,
        onClose: () => {
          handleCloseModal('groups');
        },
      });
      setModalOpened(true);
    }

    if (!isMobileM && modalOpened) {
      ModalWindows.close('MobileGroups');
      setModalOpened(false);
    }
  }, [groups, handleCloseModal, isMobileM, modalOpened, openedGroups]);

  return (
    <div className={cn(styles.labels, className)}>
      {groups?.length > 0 && (
        <Dropdown
          className={styles.label}
          label={t('ui.filters.sort-groups')}
          opened={openedGroups}
          popupWidth={740}
          onOpen={handleOpenGroups}
          onClose={() => setOpenedGroups(false)}
        >
          {!isMobileM && (
            <GroupsPopup
              label={t('ui.filters.sort-groups')}
              groups={groups}
              filterView={filterView}
            />
          )}
        </Dropdown>
      )}

      <Dropdown
        className={styles.label}
        label={labelSort}
        opened={openedOptions}
        onOpen={handleOpenOptions}
        onClose={() => setOpenedOptions(false)}
        testid='sorting-by'
      >
        {!isMobile && (
          <OptionsPopup
            label={t('ui.filters.sort-show-first')}
            options={options}
            filterView={filterView}
          />
        )}
      </Dropdown>
    </div>
  );
};

export default memo(Labels);
