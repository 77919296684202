import { memo } from 'react';
import { cn } from '@divlab/divanui';
import { IconCircleAttention } from '@divlab/divanui/icons';

import styles from './Message.module.css';

import type { FC, HTMLAttributes, ReactElement } from 'react';

export interface MessageProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  title: string;
  text: string | ReactElement;
}

const Message: FC<MessageProps> = (props) => {
  const { title, text, className } = props;

  return (
    <div className={cn(styles.message, className)}>
      <div className={styles.title}>
        <IconCircleAttention className={styles.icon} />
        {title}
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default memo(Message);
